.button2 {
    display: flex;
    height: 50px;
    padding: 0;
    /* background: #009578; */
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    width: 250px;
    
  }
  
 
  
  /* .button2:active {
    background: 8;
  } */
  
  .button__text,
  .button__icon {
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    height: 100%;
  }

  .button__icon{
    color: #fff;

  }
  .button2{
    border-top: 1px solid slategrey;
    border-right: 1px solid slategrey;
    border-bottom: 1px solid slategrey;

  }
  
  .button__icon {
    font-size: 1.5em;
    /* background: rgba(0, 0, 0, 0.134); */
    background-color: #5A4A42;
  }

  
  